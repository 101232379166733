
<template #loading>
  <b-skeleton-table :rows="rows" :columns="columns" :table-props="table_props">
  </b-skeleton-table>
</template>

<script>
export default {
  name: "TableSkeleton",
  props: {
    columns: {
      default: 10,
    },
    rows: {
      default: 12,
    },
    table_props: {},
  },
};
</script>
<style scoped>
</style>